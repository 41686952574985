
@import "/src/assets/scss/mixins/mixins-color.scss";

  
  @mixin checkbox {
  
    border: 2px solid #09042040;
    height: 30px;
    position: relative;
    width: 30px;
  
    &:before {
      background-color: white;
      border-radius: 50%;
      display: none;
      height: 16px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 16px;
    }
  
    &:checked {
      background-color: #56C06D;
    }
  
    &:checked:before {
    
      align-items: center;
      color: white;
      display: flex;
      justify-content: center;
    }
  }
  
  .square-check {
    @include checkbox;
  }
  
  .circle-check {
    @include checkbox;
    border-radius: 50%;
  }
  
  /* Style for the toggle switch */
  
  .toggle-container {
    display: block;
    height: 20px;
    position: relative;
    width: 40px;
  }
  
  .toggle-input {
    display: none;
  }
  
  .toggle-slider {
    background-color: #09042040;
    border-radius: 34px;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
  }
  
  .toggle-slider:before {
    background-color: white;
    border-radius: 50%;
    bottom: 0px;
    content: "";
    height: 16px;
    left: 0px;
    position: absolute;
    right: 0;
    top: 2px;
    transition: 0.4s;
    width: 16px;
  }
  
  .toggle-input:checked + .toggle-slider {
    background-color: map-get($colorStyles, 'primary');
  }
  
  .toggle-input:checked + .toggle-slider:before {
    transform: translateX(26px);
  }
  
  .checkbox-container label,
  .toggle-container label {
       
    display: block;
  }
  

  .special-form {
    display: flex;
    flex-direction: column;
  
    .special-label {
      font-family: 'Poppins';
      margin-bottom: 5px;
    }
  
    .special-radio {
      height: 30px;
      width: 30px;
    }
  }
  