.toolbar .searchbar {
    padding: 10px 20px !important;
    position: relative;
    top: 5px;
    transform: translateZ(598px);//fixes ios z-index
    transition: 0.3s ease;
    width: 100%;
    z-index: 598;
    &-ios {
        background: transparent;
        background: var(--ion-color-primary);
        // &.searchbar-show-cancel .searchbar-ios-cancel {
        //     position: absolute;
        //     top: 0;
        // }
        // .searchbar-search-icon {
        //     display: none;
        .searchbar-input {
            color: #000 !important;
        }
        // }
    }
}

.optionContainer {
  display: flex;
  flex-direction: column;
  width: 70%;
  mat-label {
    width: auto !important;
  }
}
.toolbar-ios-primary .searchbar-ios .searchbar-search-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,<svg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%2013%2013'><path%20fill='rgba(0,%200,%200,%200.5)'%20d='M5,1c2.2,0,4,1.8,4,4S7.2,9,5,9S1,7.2,1,5S2.8,1,5,1%20M5,0C2.2,0,0,2.2,0,5s2.2,5,5,5s5-2.2,5-5S7.8,0,5,0%20L5,0z'/><line%20stroke='rgba(0,%200,%200,%200.5)'%20stroke-miterlimit='10'%20x1='12.6'%20y1='12.6'%20x2='8.2'%20y2='8.2'/></svg>") !important;
    margin-left: 0 !important;
    margin-top: 5px;
}
.toolbar-ios-primary .searchbar-ios .searchbar-clear-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,<svg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20512%20512'><path%20fill='rgba(0,%200,%200,%200.5)'%20d='M403.1,108.9c-81.2-81.2-212.9-81.2-294.2,0s-81.2,212.9,0,294.2c81.2,81.2,212.9,81.2,294.2,0S484.3,190.1,403.1,108.9z%20M352,340.2L340.2,352l-84.4-84.2l-84,83.8L160,339.8l84-83.8l-84-83.8l11.8-11.8l84,83.8l84.4-84.2l11.8,11.8L267.6,256L352,340.2z'/></svg>") !important;
}
#searchField {
    background: white;
    display: flex;
    justify-content: center;
    position: relative;
    form {
        width: 100%;
        margin: 0 10px 0;
    }
    .searchbar-input {
        background: #F7F8FC;
        border-radius: 5px;
        border: 1px solid #E4E7EF;
        box-shadow: none;
    }
}
.searchbar-input {
    max-height: 40px;
}
// .back-button,
// .bar-buttons {
//     position: absolute;
// }
.rightHeaderBtns {
    flex: 1 1 50%;
    justify-content: flex-end;
    margin-right: 15px;
    .material-icons-outlined {
        background: #ECE8FF;
        border-radius: 5px;
       color: var(--ion-color-primary) !important;
    }
}
ion-buttons button {
    margin-right: 5px;
}
.leftHeaderBtns {
   color: var(--ion-color-primary) !important;
    flex: 1 1 50%;
    justify-content: start;
    margin-left: 15px;
    
    .backarrow {
        background: #ECE8FF;
        border-radius: 5px;
       color: var(--ion-color-primary) !important;
        height: 0;
    }

    .material-icons-outlined {
        background: #ECE8FF;
        border-radius: 5px;
       color: var(--ion-color-primary) !important;
    }
}
button[menutoggle-ios] {
        span.button-inner {
        align-items: left;
        justify-content: left;
    }
}
ion-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

button[menutoggle], .back-button {
    // align-items: center;
    // box-sizing: border-box;
    // display: flex;
    // height: 32px;
    // left: 0px;
    // margin-bottom: 0px;
    // margin-left: 0px;
    // margin-right: 6px;
    // margin-top: 0px;
    // min-width: 44px;
    // order: 1;
    // padding-bottom: 0px;
    // padding-left: 2px;
    // padding-right: 2px;
    // padding-top: 0px;
    // position: absolute;
    // right: 15px;
    // width: 44px;
    // z-index: 99;
}
ion-searchbar {
    margin: 10px 0;
}
.back-button {
    font-size: 2rem;
    margin: 0 !important;
    top: 30px;
}
ion-toolbar {
    ion-title {
        left: 0;
        padding: 0 50px;
    }
}
[dir="rtl"] {
    header {
        ion-buttons {
            left: 15px;
            right: auto;
        }
    }
}

[dir="ltr"] .toolbar .searchbar-ios .searchbar-ios-cancel {
    left: 0px;
}
[dir="rtl"] .toolbar .searchbar-ios .searchbar-ios-cancel {
    right: 0px;
}
// [dir="ltr"] .searchbar-ios.searchbar-left-aligned .searchbar-input {
//     padding-left: 100px;
// }

// [dir="rtl"] .searchbar-ios.searchbar-left-aligned .searchbar-input {
//     padding-right: 100px;
// }

.points {
    .toolbar & {
        &__counter {
            background: #fff !important;
            border-radius: 50px;
            padding: 0 0.9rem;
            .button-inner {
                flex-direction: column;
            }
            & + button {
                margin-right: 5px;
            }
        }
    }
}
.cart {
    .toolbar & {
        &__counter {
            ion-badge {
                border-radius: 50%;
                font-size: 0.5rem;
                height: 1.125rem;
                line-height: 1.125rem;
                padding: 0;
                position: absolute;
                right: -0.25rem;
                text-align: center;
                top: -0.25rem;
                width: 1.125rem;
            }
        }
    }
}
.tabs-md[tabsPlacement="top"]>.tabbar::after,
.footer-md::before,
.tabs-md[tabsPlacement="bottom"]>.tabbar::before {
    background-image: none !important;
    height: 0 !important;
}
.toolbar-background {
    background: none !important;
}

.back-button-text-ios {
    display: none;
}
.back-button-icon-ios {
    font-size: 2.4rem;
    margin-top: 10px !important;
}

button {
    background: transparent;
}
.headerButtonsWrapper {
    display: flex;
    flex: 1 0 100%;
}
.profilePic {
    // display: none;
    border-radius: 75px;
    margin-right: 25px;
    width: 40px;
}

 // app Header // 


.withHeaderShadow {
    border-radius: 10px;
    border: 1px solid rgba(86, 126, 220, 0.10);
    box-shadow: 0px 3px 10px 0px rgba(86, 126, 220, 0.10);
    margin: 10px;
}


#block-header {
    padding: 0;


    .previewHeader {
      display: flex;
      img {
        width: 100%;
      }
    }
  
    .menuLinker {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      li {
        margin-bottom: 0;
      }
    }
  
    .singleMenuSettings {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      margin-left: -25px;
    }
    .menuItemData, .gHeaderBannerData {
      padding-left: 25px;
      .colorLabel {
        margin: 20px auto;
        float: left;
      }
      .mat-form-field {
        margin-top: 10px;
        margin-left: 10px;
      }
    }
    .deleteMenuItem, .deleteGBannerItem {
      color: #CB2120;
      border: none;
      font-size: 19px;
      height: 30px;
      margin-left: 10px;
      background-color: white;
      cursor: pointer;
      align-self: flex-start;
    }
  
  
    #headerMainAreaWrapper {
      box-shadow: var(--box-shadow);
      height: 50px;
    }
  }
  
  .previewFields ul {
    margin-bottom: 50px;
    li {
      margin-bottom: 20px;
    }
  }
  
  .fileUpload {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 12px 40px #00000029;
    border-radius: 5px;
    padding: 15px 10px;
    font: normal normal normal 15px/23px Poppins;
    color: #7694E1;
    cursor: pointer;
    margin-bottom: 20px;
  
    input {
      display: none;
    }
  }
  
  .fileUploadTitle {
    font: 400 16px/28px Roboto,
      "Helvetica Neue",
      sans-serif;
    letter-spacing: normal;
    margin: 0 0 30px;
    float: left;
    margin-right: 30px;
  
    @media screen and (max-width: 1170px) {
      width: 100%;
      display: block;
    }
  }
  
  .headerActionBtns {
    flex: 1 0 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 0 15px;
    box-sizing: border-box;
    &.activeAdMobHeader {
      top: 60px;
    }
    .headerRightBtns {
      display: flex;
      flex-direction: row-reverse;
      > div {
        mat-icon {
          margin-left: 10px;
        }
        
      }
    }
  }
  #searchField {
    background: none;
    display: flex;
    justify-content: center;
    input {
      align-items: center;
      background: #F7F8FC;
      border-radius: 5px;
      border: 1px solid #E4E7EF;
      display: flex;
      height: 30px;
      justify-content: center;
      margin-left: 5px;
      padding: 0;
      width: 100%;
    }
  }
  .searchOpened {
    position: relative;
    mat-icon {
      background: transparent !important;
      left: 0;
      position: absolute;
      top: 1px;
    }
  }
  
  .squareIconBG {
        mat-icon {
            border-radius: 5px;
        }
  }

  .circleIconBG {
    mat-icon {
        border-radius: 20px;
    }
}
.squareIconBG, .circleIconBG {
  mat-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.globalColors {
  &.squareIconBG, &.circleIconBG {
    .headerActionBtns{ 
      mat-icon {
        background: var(--ion-color-primary-opacity);
      }
  }
  }
}
.noIconBG {
  .headerActionBtns{ 
    mat-icon {
      background: transparent !important;
    }
  }
}
.disable_header {
  background: #cacaca !important;
}
.headerLeftBtns, .headerRightBtns {
  display: flex;
  mat-icon {
    height: 24px;
    padding: 3px;
    width: 24px;
    font-size: 20px;
    display: flex;
    align-items: center;
  }
}

.noIconBG {
  .headerActionBtns {
    .headerRightBtns > div {
      margin-left: 0;
    }
  } 
}

  .globalColors {
    mat-icon {
      color: var(--ion-color-primary) !important;
      background: var(--ion-color-primary-opacity) !important;
    }
  }
  .backgroundImageHeader {
    background-position: center;
    background-size: 40px;
    background-repeat: no-repeat;
  }

  .searchOpened {
    mat-icon {
      background: transparent !important;
    }
  }