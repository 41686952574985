.arabic {
    direction: rtl;
    font-family: "Tajawal", sans-serif;
    .headerRightWrapper {
        justify-content: left !important;
    }
    #mainMobilePreviewShape, .editBlock, .blockControlHandlers {
        direction: ltr;
    }
    .stepper::before {
        right: 100% !important;
    }
    .custom-file-input::before {
        content: "إضافة صورة جديدة" !important;
    }
    #countryCode {
        margin-left: 5% !important;
        margin-right: 0 !important;
    }
    .simpleStyle .strike {
        padding-right: 5px;
    }
    .roundedStyle .twoColumns .pricesMiniWrapper .mainPrice {
        margin-right: 0px;
        margin-left: 5px;
    }
    .roundedStyle .threeColumns .pricesMiniWrapper .mainPrice {
        margin-right: 0px;
        margin-left: 5px;
    }
    .tableWrapper table td , .tableWrapper table th  {
        text-align: right;
    }
    .open li.toggler button, .stacksBlockContainer .blockSidebar p, #viewsWrapper #viewsContainer ul li h4  {
        font-family: "Tajawal", sans-serif !important;
    }
    #projectSelector #projectWrapper {
        justify-content: right !important;
    }
    #projectSelector {
        padding: 15px 0px 15px 0 !important;
    }
    #headerSecondRow {
        padding: 0 40px !important;
    }
    
    .open li p {
        font-size: 16px !important;
    }
    .option {
        width: 80%;
    }
    .open li p {
        text-align: right !important;
    }
    .containerWrapper .tableWrapper table mat-accordion mat-expansion-panel .fileWrapper button {
        margin-left: 0 !important;
        margin-right: 30px;
    }
    .preferred {
        margin: 0 15px 0 0 !important;
    }
    #saveVersion {
        margin-left: 0 !important;
        margin-right: 20px;
    }
    .newProduct .productDetail .variationWrapper mat-accordion mat-expansion-panel .varDescription .varPrice mat-form-field {
        width: auto !important;
    }
    .newProduct .productDetail .productForm .left , .right {
        margin-left: 100px;
        margin-right: 0 !important;
    }
    .howItWorks span {
        padding-right: 0;
        padding-left: 5px;
    }
    .containerWrapper .tableWrapper table mat-accordion mat-expansion-panel mat-expansion-panel-header .mat-content img {
        margin: 0 0 0 30px !important;
    }
    .login-options img {
        padding: 0 5px 0 15px !important;
    }
    .stacksBtn mat-icon {
        padding-right: 0 !important;
        padding-left: 7px !important;
    }
    .previewFields , .styleFields {
        direction: rtl;
    }
    .headerActionBtns .headerRightBtns > div mat-icon {
        margin-right: 10px;
        margin-left: 0;
    }
    #searchField input {
        margin-left: 0;
    }
    .searchOpened mat-icon {
        right: 0;
    }
    .tableWrapper {
        margin: 0 30px !important;
    }
    .containerWrapper .tableWrapper table mat-accordion mat-expansion-panel .catDesc {
        margin: 20px 40px 30px 40px !important;
    }
    mat-form-field {
        direction: ltr !important;
    }
    .currencyWrapper p {
        margin: 0 10px 0 10px !important;
    }
    sidebar {
        .open li button {
            padding: 10px;
            min-width: auto !important;
        }
        .android , .ecommerce {
            padding-left: 5px !important;
        }
        ul {
            padding-right:  10px;
        }     
        .sidebarToggle {
            transform: translate(0, 0%) rotate(180deg) !important;
        }
        .openSidebarArr {
            transform: translate(-50%, 0) rotate(0deg) !important;
            float: left !important;
        }
   
    }
    .ArabicButton {
        border: none;
        font-family: "Tajawal", sans-serif !important;
        font-size: 14px;
        padding: 10px;
        width: auto !important;
    }
      .toggler {
          align-items: flex-start !important;
      }
      blocks-wrapper {
        margin-right: 3px;
      }
      .stacksBtn {
        margin: 0px 10px;
      }
      .blockControlHandlers {
        right: calc(50% - 65px) !important;
      }
      .projectStatusDot {
        margin: 0 0 0 10px;
      }
      .sidabarWrapper {
        margin-right: 0 !important;
      }
      #builderLogo {
        a {
            padding: 0 10px 0 0px !important;
        }
      }
      #projectSelector {
        padding: 15px 0px 15px 45px !important;
      }
      .mat-mdc-slider {
        direction: ltr;
      }
      .custom-file-input::before {
        content: "اختر صورة" !important;
      }
      .containerWrapper .tableWrapper table mat-accordion mat-expansion-panel .fileWrapper button {
        margin: 0 30px 0 0 !important;
      }
      .toggleTour {
        svg {
            padding: 0 0 0 15px !important;
        }
      }
      #settingsTabs {
        display: -webkit-inline-box;
      }
      .translationTable {
        th , td {
            text-align: right !important;
        }
      }
      mat-paginator {
        direction: ltr;
      }
      .pointsContainer h3 {
        text-align: right !important;
        padding: 0 0 0 50px !important;
      }
      .mat-mdc-slide-toggle .mdc-switch:enabled+.mdc-label {
        padding: 0 40px 0 0 !important;
    }
    .pointsContainer .optionContainer .small-text {
        padding: 0 0 20px 50px !important;
    }
    .pointsContainer .optionContainer .middle {
        padding:0 50px 20px !important;
    }
}