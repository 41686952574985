@import "/src/assets/scss/mixins/mixins-color.scss";


$tag-styles: (
      'L': (
          Property1: 'L',
          cancel: true,
      ),
      'M': (
          Property1: 'M',
          cancel: true,
      ),
      'S': (
          Property1: 'S',
          cancel: true,
      ),
    );

    @mixin tag-style($size) {
      $properties: map-get($tag-styles, $size);

      @if $properties {
        @each $property, $value in $properties {
          #{$property}: $value;
        }
      }
    }

    .tag {
      background: #7257FF26;
      border-radius: 7px;
      color: map-get($colorStyles, 'primary');
      display: flex;
      margin: 5px;
      padding: 5px 10px;
      width: fit-content;

      &.tag-L {
        @include tag-style('L');
        @include font-style('Semibold-L');
      }

      &.tag-M {
        @include tag-style('M');
        @include font-style('Semibold-M');
      }

      &.tag-S {
        @include tag-style('S');
        @include font-style('Semibold-S');
      }
    }
