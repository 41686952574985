.stacksBlockContainer {
  height: 100%;
  .blockSidebar {
    height: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    @media screen and (max-width: 768px) {
      flex-direction: row;
      justify-content: flex-start;
      padding-left: 15px;
      border-radius: 0;
      img {
        width: 40%;
        margin-top: 6px;
        max-width: 25px;
        margin-right: 10px;
      }
    }
    mat-icon {
      font-size: 40px;
      width: auto;
      height: auto;
      margin-bottom: 5px;
    }
    p {
      color : #666666;
      font-size: 13px;
      font-family: 'poppins';
      margin-top: 5px;
      margin-bottom: 0;
      text-align: center;
      @media screen and (max-width: 1024px) {
        font-size: 12px;
        font-weight: bold;
      }
    }
    &.disabled {
      background: #ffffff;
      cursor: initial;
      filter: blur(1px);
      opacity: 50%;
    }
  }
}