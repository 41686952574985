/* mixins scss */ 
@import "/src/assets/scss/mixins/mixins-check-radio-toggle.scss";
@import "/src/assets/scss/mixins/mixins-color.scss";
@import "/src/assets/scss/mixins/mixins-dropdown.scss";
@import "/src/assets/scss/mixins/mixins-fonts.scss";
@import "/src/assets/scss/mixins/mixins-footer.scss";
@import "/src/assets/scss/mixins/mixins-header.scss";
@import "/src/assets/scss/mixins/mixins-input.scss";
@import "/src/assets/scss/mixins/mixins-nav.scss";
@import "/src/assets/scss/mixins/mixins-phone-number-input-field.scss";
@import "/src/assets/scss/mixins/mixins-post.scss";
@import "/src/assets/scss/mixins/mixins-search.scss";
@import "/src/assets/scss/mixins/mixins-tags.scss";
@import "/src/assets/scss/mixins/mixins-textarea.scss";
:root {
    --box-shadow: 0px 2px 10px #00000029;
    --main-font-family: "poppins", "Helvetica Neue", sans-serif;
    --main-font-size: 18px;
    --main-letter-spacing: 0.5px;

    --title-font-size: 15px;
    --title-font-family: 'poppins';
    --title-font-weight: 'light';
}