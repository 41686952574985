h1{
  font: 400 32px Roboto, "Helvetica Neue", sans-serif;
  padding: 40px 40px 0 40px;
  color: #6084DE;
  text-align: center;
  margin: 0;
}

.productsWrapper{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  box-shadow: 6px 0px 32px #00000029;
  box-sizing: border-box;
  margin: 0 auto;
  min-height: 740px;
  overflow: hidden;
  width: 1232px;

  .translateTextInput {
    border-radius: 5px;
    border: 1px solid #DBDBDB;
    max-width: 200px;
    outline: none;
    padding: 5px;
    @media screen and (max-width: 768px){
        max-width: 125px;
    }
  }

  @media screen and (max-width: 768px){
      width: 100%;
  }

  .addProductWrapper{
      align-items: center;
      display: flex;
      flex-direction: column;
      margin: auto;

      button{
          background-color: #44AF69;
          border-radius: 5px;
          border: none;
          color: #ffffff;
          cursor: pointer;
          font-family: "Poppins";
          padding: 10px 20px;
      }
  }
}

td {
  white-space: pre;
}
.tableWrapper {
  margin: 0 auto;
  max-width: 1100px;
  overflow-x: auto;
  padding-top: 60px;
  @media screen and (max-width: 768px){
      width: 100%;
      max-width: 100%;
  }

  .tableHeader{
      display: flex;
      width: 100%;
      border-bottom: 2px solid #6084DE;
      border-radius: 0;
      align-items: center;
      padding-bottom: 5px;

      h1{
          text-align: left;
          padding: 0;
          margin-left: 10px;
          font-size: 15px;
          @media screen and (max-width: 768px){
              font-size: 12px;
          }
      }

      .headerBtns{
          display: flex;
          padding-bottom: 15px;
          white-space: nowrap;
          width: 100%;
          justify-content: flex-end;
          
          mat-form-field {
            margin-right: 30px;
          }

          button{
              font-family: "Poppins";
              font-size: 14px;
              font-weight: 400;
              border: none;
              margin: 0 30px 0 auto;
              cursor: pointer;
              @media screen and (max-width: 768px){
                  font-size: 12px;
                  margin: 0 10px 0 15px;
              }
          }
            input[type=text]{
                border-radius: 5px !important;
                border: 1px solid #DBDBDB !important;
                font-size: 14px !important;
                height: unset !important;
                line-height: unset !important;
                margin-right: 20px !important;
                max-width: 150px !important;
                outline: none !important;
                padding: 10px !important;
                text-align: left !important;
                width: unset !important;
                @media screen and (max-width: 768px){
                    max-width: 125px;
                }
            }
        
          .tablePaginator{
              display: flex;
              flex-wrap: nowrap;
              ::ng-deep .mat-paginator-container {
                  flex-wrap: nowrap;
                  padding: 0;
                  @media screen and (max-width: 768px){
                      justify-content: flex-start;
                  }
              } 
              ::ng-deep .mat-paginator-range-label{
                  margin: 0;
              }
              
          }
      }
  }

  table {
      width: 100%;
      max-width: 1100px;
      margin: 0px auto;
      box-shadow: none;
      @media screen and (max-width: 768px){
          max-width: 100%;
          margin: 0;
      }

      tr:nth-child(even) {
          background-color: #ffffff;
      }

      tr:nth-child(odd) {
        background-color: #f1f1f1;
      }

      .default {
        border-radius: 5px;
        border: none;
        padding: 10px 20px 10px 0;
        width: 120px;
      }
      .defaultNotSelected {
        color: red;
      }

      .defaultSelected {
        color: #44AF69;
      }
      
      th {
          color: #6084DE;
          font-size: 14px;
          font-family: "Poppins", sans-serif !important;
          background-color: #ffffff !important;
          @media screen and (max-width: 768px){
              padding: 0px;
              padding-left: 5px;
          }

          .hasIcons{
              display: flex;
              padding: 18px 0;

              .headingIcons{
                  display: flex;
                  flex-direction: column;

                  span{
                      height: 8px;
                      font-size: 15px;
                  }
              }
          }
      }
      
      td{
          border: none;
          color: #000000;
          font-family: "Poppins", sans-serif !important;
          font-size: 14px;
          max-width: 200px;
          width: 200px;
          @media screen and (max-width: 768px){
              padding: 10px;
              width: auto;
              height: auto;
              white-space: normal;
              border-left: 1px solid #ffffff;
          }
      }
    }
}
.actionColumn {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;    
    white-space: nowrap;
    width: 150px;
  
    th, td {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 150px;
    }
  }
  .saveChanges {
    margin-top: 15px;
  }
  .beforeEdit {
    display: flex;
    justify-content: space-between;

    p {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .LangTable {
    td {
        width: auto !important;
    }
  }
.editIcon {
  border-radius: 5px;
  border: none;
  margin-left: 5px;
  padding: 0;
}