$fontStyles: (
  'heading2XL': (font-size: 32px, font-weight: 700, line-height: 48px),
  'headingL':   (font-size: 20px, font-weight: 700, line-height: 30px),
  'headingM':   (font-size: 16px, font-weight: 700, line-height: 24px),
  'headingS':   (font-size: 14px, font-weight: 700, line-height: 21px),
  'headingXL':  (font-size: 26px, font-weight: 700, line-height: 39px),
  'headingXs':  (font-size: 12px, font-weight: 700, line-height: 18px),
  'medium2XL':  (font-size: 20px, font-weight: 500, line-height: 30px),
  'mediumL':    (font-size: 16px, font-weight: 500, line-height: 24px),
  'mediumM':    (font-size: 14px, font-weight: 500, line-height: 21px),
  'mediumS':    (font-size: 12px, font-weight: 500, line-height: 18px),
  'mediumXL':   (font-size: 18px, font-weight: 500, line-height: 27px),
  'mediumXs':   (font-size: 10px, font-weight: 500, line-height: 15px),
  'regular2XL': (font-size: 20px, font-weight: 400, line-height: 30px),
  'regularL':   (font-size: 16px, font-weight: 400, line-height: 24px),
  'regularM':   (font-size: 14px, font-weight: 400, line-height: 21px),
  'regularS':   (font-size: 12px, font-weight: 400, line-height: 18px),
  'regularXL':  (font-size: 18px, font-weight: 400, line-height: 27px),
  'regularXs':  (font-size: 10px, font-weight: 400, line-height: 15px),
  'semiBold2XL':(font-size: 20px, font-weight: 600, line-height: 30px),
  'semiBoldL':  (font-size: 16px, font-weight: 600, line-height: 24px),
  'semiBoldM':  (font-size: 14px, font-weight: 600, line-height: 21px),
  'semiBoldS':  (font-size: 12px, font-weight: 600, line-height: 18px),
  'semiBoldXL': (font-size: 18px, font-weight: 600, line-height: 27px),
  'semiBoldXs': (font-size: 10px, font-weight: 600, line-height: 15px),
);

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@mixin font-style($style, $additional-properties: ()) {
  $styleProperties: map-get($fontStyles, $style);
  
  @if $styleProperties {
    font-family: 'Poppins';
    @each $property, $value in $styleProperties {
      #{$property}: $value;
    }
    
    @each $property, $value in $additional-properties {
      #{$property}: $value;
    }
  }
}

.regular2XL {
  @include font-style('regular2XL');
}

.regularXL {
  @include font-style('regularXL');
}

.regularL {
  @include font-style('regularL');
}

.regularM {
  @include font-style('regularM');
}

.regularS {
  @include font-style('regularS');
}

.regularXs {
  @include font-style('regularXs');
}

.medium2XL {
  @include font-style('medium2XL');
}

.mediumXL {
  @include font-style('mediumXL');
}

.mediumL {
  @include font-style('mediumL');
}

.mediumM {
  @include font-style('mediumM');
}

.mediumS {
  @include font-style('mediumS');
}

.mediumXs {
  @include font-style('mediumXs');
}

.semibold2XL {
  @include font-style('semiBold2XL');
}

.semiboldXL {
  @include font-style('semiBoldXL');
}

.semiboldL {
  @include font-style('semiBoldL');
}

.semiboldM {
  @include font-style('semiBoldM');
}

.semiboldS {
  @include font-style('semiBoldS');
}

.semiboldXs {
  @include font-style('semiBoldXs');
}

.heading2XL {
  @include font-style('heading2XL');
}

.headingXL {
  @include font-style('headingXL');
}

.headingL {
  @include font-style('headingL');
}

.headingM {
  @include font-style('headingM');
}

.headingS {
  @include font-style('headingS');
}

.headingXs {
  @include font-style('headingXs');
}