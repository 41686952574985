@import "/src/assets/scss/mixins/mixins-fonts.scss";

.horizontal-slider-container{
  &.listStyle {
    .roundedStyle .sale {
      left: 70px;
    }
    .horizonal-slider {
      display: flex;
      overflow-x: auto;
      justify-content: space-between;
      padding-bottom: 10px !important;
      &.oneColumn {
        padding: 0;
        margin-left: 10px;
        .fade {
          padding: 10px 0px 0 10px;
        }
        .flex-container {
          flex-direction: row;
          height: 100%;
          .picture-container {
            max-width: unset;
            height: auto;
          }
        }
      }
    }
  }
  .mainPrice {
  .option {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: space-between;
    li {
      display: flex;
      flex-direction: column;
    }
    }
    .centered {
      justify-content: center;
    }
  }
  button {
    @include font-style('semiBoldM');
    align-content: center;
    align-items: center;
    align-self: center;
    background-color: var(--ion-color-primary);
    border: none !important;
    color: var(--ion-color-text);
    display: flex;
    justify-content: center;
    padding: 5px 10px;
    width: auto;
    color: #fff;
    mat-icon {
      font-size: 18px;
      height: auto;
      width: auto;
    }
  }
  img {
    border-radius: 10px;  
    height: 100%;
    object-fit: cover;
    width: 100%; 
  }
  .sale {
    @include font-style('semiBoldXs');
    background: #ff3535;
    border-radius: 5px;
    box-shadow: none;
    color: #fff;
    position: absolute;
    text-align: center;
  }
  .flex-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
  .picture-container { 
    border-radius: 10px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
    display: flex;
    height: auto;
    justify-content: center;
    position: relative;
    width: 100%;
  }
  .outofstock {
    @include font-style('regularL');
    color: red;
    float: left;
    margin-bottom: 10px;
  }
  .horizonal-slider {
    display: grid;
    grid-gap: 15px;
    list-style: none;
    margin: 0;
    padding: 10px;
  }
  .heading {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    @include font-style('regularM');
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .price{
    @include font-style('regularXs');
    color: var(--ion-color-primary);
  }
  .strike {
    align-self: flex-start;
    font-size: 13px;
    text-decoration: line-through;
    font-weight: 500;
    color: var(--ion-color-primary);
    padding-top: 4px;
  }
  .mainPrice {
    @include font-style('headingM');
    color: black;
    .fromText {
      font-weight: 400;
      font-size: 14px;
      color: #7c7c7c
    }
  }
  .pricesMainWrapper {
    display: flex;
    flex-direction: row;
  }
}

  .oneColumn {
    .pricesMainWrapper {
      padding: 0;
    }
    .heading {
      padding: 0;
    }
    .horizonal-slider {
      grid-template-columns: 1fr;
    }
    .flex-container {
      flex-direction: row;
      height: 100px;
      justify-content: space-between;
      width: 100%;
    }
    .picture-container { 
      display: flex;
      height: auto;
      justify-content: center;
      position: relative;
      width: 55%;
    }
    .fade {
      align-self: flex-start;
      padding: 0 10px;
      top: 0 !important;
      width: 100%;
      height: 100px;
      .description {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    .glassStyle .fade {
      align-self: flex-start;
    }
    .glassStyle .heading {
      padding: 0px 0 5px;
    }  
    .glassStyle .sale {
      right: 5px;
      padding: 2px;
      bottom: 5px;
      top: auto;
      left: auto;
    }
    .sharpStyle .pricesMainWrapper {
      padding:0; 
    }
    .sharpStyle .flex-container .sale {
      left: 3px;
      padding: 2px 5px;
    }
    .roundedStyle .sale {
      left: 50px;
      padding: 2px;
      top: 8px;
   }
   .roundedStyle .pricesMainWrapper {
      justify-content: flex-start;
   }
   .roundedStyle .pricesWrapper {
      align-items: center;
      flex-direction: row-reverse;
   }
   .roundedStyle .horizontal-slider-container .strike {
     padding-right: 5px;
   }
   .roundedStyle .roundedButton {
      display: flex;
      float: right;
   }
   .roundedStyle .picture-container {
      clip-path: none;
   }
   .strike {
    padding-right: 10px;
   }
  }

  .twoColumns {
    .horizonal-slider {
      grid-template-columns: 1fr 1fr;
    }
    .flex-container {
      justify-content: space-between;
      width: 150px;
    }
    .description {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 10px;
    }
  }

  .threeColumns {
    .horizonal-slider {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .simpleStyle .sale {
    left: 5px;
    padding: 2px;
    top: 5px;
    }
    .glassStyle .sale {
    left: 5px;
    padding: 2px;
    top: 5px;
    }
    .sharpStyle .flex-container .sale {
    border-radius: 0 10px 10px 0;
    left: 3px;
    padding: 2px 4px;
    top: 6px;
    }
    .sharpStyle button {
    padding: 5px;
    }
    .roundedStyle .sale {
    left: 45px;
    padding: 2px;
    }
    .roundedStyle button {
    height: 35px;
    width: 35px;
    }
    .flex-container {
      justify-content: space-between;
      width: 100px;
    }
    .description {
      min-height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .simpleStyle {
    .listStyle  {
      .oneColumn {
        .item {
          width: 100%;
        }
        .picture-container {
          width: 190px;
          max-width: unset;
          height: auto;
        }
      }
      .twoColumns {
        .item {
          width: 100%;
        }
        .picture-container {
          width: 150px;
          max-width: unset;
          height: auto;
        }
      }
    } 
      .pricesMainWrapper {
        flex-direction: column;
      }
      .sale {
        left: 10px;
        padding: 5px 8px;
        top: 10px;
      }
      .pricesWrapper {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        padding-bottom: 5px;
      }
      .price {
        align-self: center;
      }
      .strike {
        padding-left: 5px;
      }
      button {
        border-radius: 7px;
        padding: 5px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        mat-icon {
          font-size: 20px;
          display: flex;
          align-items: center;
          padding-bottom: 1%;
        }
      }
      .pricesMiniWrapper {
        display: flex;
        flex-direction: row-reverse;
      }
      .oneColumn .sale {
        padding: 2px;
      }
      .twoColumns {
        .description {
          min-height: 100px;
        }
      }
      .threeColumns {
        button {
          font-size: 10px;
        }
      }
  }

  .glassStyle {
    .listStyle  {
      .twoColumns {
        .picture-container {
          max-width: unset;
          height: auto;
        }
      }
      .threeColumns {
        .item {
          width: 100%;
        }
      } 
    }
      .sale {
        left: 10px;
        padding: 5px 8px;
        top: 10px;
      }
      .oneColumn {
        .fade {
          height: 85px;
        }
        .picture-container {
          height: 85px;
          max-width: 85px;
        }
      }
      .fade {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        bottom: 85px;
        height: 85px;
        position: relative;
      }
      .pricesWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .description {
        padding: 5px;
      }

      .pricesMainWrapper {
        justify-content: space-between;
      }
      button {
        border-radius: 10px;
        padding: 8px;
        .material-icons {
          font-size: 20px;
        }
      }
      .pricesMiniWrapper {
        display: flex;
        flex-direction: column-reverse;
      }
      .oneColumn {
        .flex-container {
          height: 85px;
        }
        .description {
          justify-content: flex-start;
          padding: 10px 0 7px;
          height: auto;
        }
        .sale {
          right: 5px;
          padding: 2px;
          bottom: 5px;
          top: auto;
          left: auto;
        }
      }
      .twoColumns , .threeColumns {
        .fromText {
          color: black !important;
        }
      }
      .twoColumns {
        .flex-container {
          position: relative;
          width: 155px;
          .fade {
            bottom: 0;
            height: 43%;
            position: absolute;
            width: 100%;
          }
        }
        .description {
          margin:0;
          padding: 5px;
        }
        .mainPrice {
          font-size : 16px;
          line-height: 20px;
        }
        button {
          border-radius: 10px;
          padding: 5px;
        }
      }
      .threeColumns {
        .flex-container {
          position: relative;
          .fade {
            bottom: 0;
            height: 40%;
            position: absolute;
            width: 100%;
          }
        }
        .description {
          margin-top: 0px;
          min-height: 0;
        }
        .mainPrice {
          font-size: 16px;
          margin-bottom: -16%;
        }
        .strike {
          font-size: 12px;
        }
        .picture-container {
          display: flex;
          height: 240px;
          justify-content: center;
          position: relative;
          width: 100%;
        }
        .sale {
          left: 5px;
          padding: 2px;
        }
        .pricesWrapper {
          margin-top: 3px;
        }
        button {
          border-radius: 10px;
          margin-top: 5px;
          padding: 6px;
        }
        .pricesMiniWrapper {
          margin-top: -3px;
        }
      }
  }

  .sharpStyle {

    .picture-container {
      box-shadow: none;
      border-radius: 0;
    }

    .flex-container {
      box-shadow: 0px 5px 6px #00000029;
    }

    .sale {
      left: 10px;
      padding: 5px 8px;
      top: 10px;
    }
    .listStyle {
      .threeColumns , .horizonal-slider {
        justify-content: normal;
      }
      .oneColumn {
        .picture-container {
          width: 160px !important;
        }
        button {
          margin-right: 10px; 
        }
        .description {
          padding: 0;
          justify-content: center;
          height: 100%;
        }
        .fade {
          padding: 5px !important;
        }
      }
    }
    
    .oneColumn {
      .fade {
        height: 85px;
        border-radius: 0;
        backdrop-filter: none;
      }
      .item {
        img {
          border-radius: 0;
          padding: 5px;
          box-sizing: border-box;
        }
      }
      .flex-container {
        box-shadow: 0px 0px 9px 0px #00000029;
      }
      .picture-container {
        height: 85px;
        max-width: 85px;
      }
      mat-icon {
        padding: 5px;
        border-radius: 10px;
      }
      button {
        padding: 0;
      }
      .pricesWrapper {
        margin-top: 10px;
      }
      .sale {
        padding: 2px 5px;
      }
    }
    .twoColumns {
      display: grid;
      list-style: none;
      margin: 0;
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
      .flex-container {
        position: relative;
        .fade {
          bottom: 0;
          height: 35%;
          width: 100%;
          background: white;
          border-radius: 0;
        }
      }
      .description {
        margin:0;
        padding: 5px 10px;
      }
      .mainPrice {
        font-size : 16px;
        line-height: 20px;
      }
      button {
        padding: 5px;
      }
      .picture-container {
        img {
          border-radius: 0;
          box-sizing: border-box;
        }
      }
    }
    .threeColumns {
      display: grid;
      list-style: none;
      margin: 0;
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
      .flex-container {
        position: relative;
        .fade {
          bottom: 0;
          height: 35%;
          width: 100%;
        }
      }
      .fromText {
        font-size: 12px;
      }
      .description {
        height: 100%;
        box-sizing: border-box;
        justify-content: space-evenly;
      }
      .sale {
        border-radius: 0 10px 10px 0;
        left: 5px;
        padding: 2px 4px;
        top: 10px;
      }
      .mainPrice {
        font-size: 16px;
      }
      .strike {
        font-size: 12px;
      }
      .picture-container {
        display: flex;
        height: 200px;
        justify-content: center;
        position: relative;
        width: 100%;
      }
      .pricesWrapper {
        margin-top: 3px;
      }
      button {
        padding: 5px;
        margin-top: 5px;
      }
      .pricesMiniWrapper {
        margin-top: -3px;
      }
      img {
        border-radius: 0;
        padding: 5px;
        box-sizing: border-box;
      }
    }

    .pricesWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .description {
      padding: 5px;
    }

    .pricesMainWrapper {
      justify-content: space-between;
    }
    button {
      padding: 8px;
    }
    .pricesMiniWrapper {
      display: flex;
      flex-direction: column-reverse;
    }

    .oneColumn {
      .flex-container {
        height: 85px;
      }
      .description {
        justify-content: flex-start;
        padding: 10px 0 7px;
        height: auto;
      }
    }
   }

  .roundedStyle {
    .listStyle {
      .twoColumns {
        .item {
          width: 100%;
        }
      }
      .threeColumns {
        .item {
          width: 100%;
        }
      }
    }

   .pricesMiniWrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    }
    .sale {
      border-radius: 20px;
      left: 70%;
      padding: 2px 5px;
      top: 10px;
    }

    .description {
      display: flex;
      flex-direction: column;
    }

    .pricesMainWrapper {
      justify-content: center;
      text-align: center;
    }

    .heading {
      @include font-style('mediumM');
      display: flex;
      flex-direction: column-reverse;
      text-align: center;
      
      .headingName {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .mainPrice {
      padding-right: 5px;
      text-align: center;
    }
    button { 
      border-radius: 50%;
      fill: var(--ion-color-primary);
      filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.15));
      flex-shrink: 0;
      height: 40px;
      padding: 0;
      width: 40px;
      border: 4px solid white !important;
    }
    .oneColumn {
      .description {
        justify-content: flex-start;
        padding: 10px 0 0px;
        height: auto;
      }
      .sale {
        left: 65%;
        padding: 2px;
      }
      .pricesMiniWrapper {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
        justify-content: left;
        padding-top: 5px;
      }
      .heading{
        text-align: left;
      }
      .mainPrice {
        padding-right: 10px;
        text-align: left;
      }
      .item {
        padding: 5px;
      }
    }
    .twoColumns  {
      .flex-container {
          justify-content: flex-start;
      }
      .description {
        margin-top: -10px;
      }
      .pricesMiniWrapper {
        display: flex;
        flex-direction: row-reverse;
        justify-content: left;
        align-items: center;
        margin: 0 auto;
        .mainPrice {
          margin-right: 5px;
        }
      }
      .heading {
        margin-top: -10px;
        text-align: center;
        flex-direction: column-reverse;
      }
      .pricesWrapper {
        display: flex;
        flex-direction: column-reverse
      }
      .picture-container {
        min-height: calc(100% - 65px);
      }
      .roundedButton {
        display: flex;
        justify-content: center;
      }
    }
    .threeColumns {
      .flex-container {
        justify-content: flex-start;
      }
      .sale {
        left: 60%;
        padding: 2px;
      }
      .picture-container {
        min-height: 100px;
      }
      .description {
        margin-top: -10px;
        justify-content: space-around;
      }
      .pricesMiniWrapper {
        display: flex;
        flex-direction: row-reverse;
        justify-content: left;
        align-items: center;
        margin: 0 auto;
        .mainPrice {
          margin-right: 5px;
        }
      }
      .heading {
        margin-top: -10px;
        text-align: center;
        flex-direction: column-reverse;
      }
      .pricesWrapper {
        display: flex;
        flex-direction: column-reverse
      }
      .roundedButton {
        display: flex;
        justify-content: center;
      }
    }
  }

  .glassStyle, .sharpStyle, .roundedStyle {
    .listStyle {
      .oneColumn {
        .item {
          padding: 0;
        }
        .picture-container {
          width: 160px !important;
        }
        button {
          margin-right: 10px; 
        }
        .description {
          padding: 0;
          justify-content: center;
          height: 100%;
        }
        .fade {
          padding: 5px !important;
        }
        img {
          padding: 5px;
          box-sizing: border-box;
        }
      }
    }
  }

  .pricesMiniWrapper {
    &.variableProductPrices {
      flex-direction: row;     
      width: auto;
      justify-content: space-between;     
    } 
  }

  .roundedStyle {
    .listStyle {
      .oneColumn {
        .pricesWrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
  }
