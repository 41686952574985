@import "/src/assets/scss/mixins/mixins-color.scss";

$input-states: (
   'active': (
    states: 'active',
    show-label: true,
    show-icon: true,
    error-message: true,
    border: 1.5px solid #09042026,
    ),
  'disabled': (
    states: 'disabled',
    show-label: true,
    show-icon: true,
    error-message: true,
    background: #09042026,
    border: 1.5px solid #09042026,
  ),
  'error': (
    states: 'error',
    show-label: true,
    show-icon: true,
    error-message: true,
    background: #ffe1e1ff,
    border: 1.5px solid #FF3535,
  ),
);

@mixin input-style($state) {
  $properties: map-get($input-states, $state);

  @if $properties {
    @each $property, $value in $properties {
      #{$property}: $value;
    }
  }
}

.input {
  display: block;
  margin: 10px;
  border-radius: 10px;
  gap: 10px;    
  padding: 14px 15px;
  @include font-style('semiBoldL');

  @each $state, $styles in $input-states {
    &.input-#{$state} {
      @include input-style($state);
    }
  }
}

.input.input-active {
    @include input-style('active');
    &:focus {
        box-shadow: 0px 1px 8px 0px map-get($colorStyles, 'primary');
        border-color: map-get($colorStyles, 'primary'); 
        color: map-get($colorStyles, 'primary');
  }
}

  .input.input-disabled {
    @include input-style('disabled');
  }
  
  .input.input-error {
    @include input-style('error');
    &:focus {
        box-shadow: 0px 1px 8px 0px #FF3535;
        border-color: #FF3535;
  }
}
  