body {
    .stacksBtn {
        width: auto !important;
        height: auto;
        border-radius: 5px;
        margin-left: 20px;
        padding: 10px;
        border: 0;
        cursor: pointer;
        text-decoration: none;
        line-height: 0;
        font-weight: normal;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-wrap: nowrap;
        margin-bottom: 10px;
        &:disabled {
            opacity: 0.5;
            cursor: not-allowed !important;
        }
        mat-icon {
            padding-right: 7px;
        }
        &.green {
            background: #44AF69;
            color: white;
        }
        &.blue {
            background: #567edc;
            color: white;
        }
        &.smallBtn {
            height: 20px;
        }
        &.red {
            background: #CB2121;
            color: white;
        }
        &.refetchData {
            margin: 20px 0;
        }
        &.lightBtn {
            background: white;
            height: auto;
            line-height: initial;
            &.green {
                color: #44AF69;
            }
            &.blue {
                color: #567edc;
            }
            &.red {
                color: #CB2121;
            }
        }
        &.glow {
            color: #fff;
            text-transform: uppercase;
            padding: 16px 34px;
            &.blue {
                background: #567edc;
                box-shadow: 0 0 0 0 #567edc;
                animation: glowBlue 1.4s linear infinite;
                color: white;
            }
            &.green {
                background: #44AF69;
                box-shadow: 0 0 0 0 #44AF69;
                animation: glowGreen 1.4s linear infinite;
                color: white;
            }
            &.red {
                background: #CB2121;
                box-shadow: 0 0 0 0 #CB2121;
                animation: glowRed 1.4s linear infinite;
                color: white;
            }
        }
    }
    .mat-menu-content {
        button {
            font-size: var(--title-font-size);
            font-family: var(--title-font-family);
            font-weight: var(--title-font-weight);
            color: #424242;
        }
    }
    .deleteButton {
        // background-color: #f44336ff !important;
        color: red !important;
        display: flex;
        margin: 0 auto;
      }
      .deleteIcon {
        color: #ff0000;
        cursor: pointer;
      }
      .editIcon {
        color: #44af69;
      }
}

@keyframes glowBlue {
    0% {
      box-shadow: 0 0 0 0 #567edc;
    }
    
    50% {
      box-shadow: 0 0 30px 0 #567edc;
    }
}
@keyframes glowGreen {
    0% {
      box-shadow: 0 0 0 0 #44AF69;
    }
    
    50% {
      box-shadow: 0 0 30px 0 #44AF69;
    }
}
@keyframes glowRed {
    0% {
      box-shadow: 0 0 0 0 #CB2121;
    }
    
    50% {
      box-shadow: 0 0 30px 0 #CB2121;
    }
}

.disabledBtn {
    opacity: 0.5;
    cursor: not-allowed !important;
    // add lock icon
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url("/assets/images/lock.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
    }
}

.glowingBlueElement {
    box-shadow: 0 0 0 0 #567edc;
    animation: glowBlue 1.4s linear infinite;
}
.glowingGreenElement {
    box-shadow: 0 0 0 0 #44AF69;
    animation: glowGreen 1.4s linear infinite;
}
.glowingRedElement {
    box-shadow: 0 0 0 0 #CB2121;
    animation: glowRed 1.4s linear infinite;
}