#settingsTabs {
  width: 100%;
  height: 40px;
  a {
    padding: 10px;
    display: block;
    float: left;
    width: 50%;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: 1px solid #f0f0f0;
    &.active {
      color: white;
      background: #567EDC;
      @media screen and (min-width: 768px) {
        background: white;
        border-bottom: 3px solid #567EDC;
        color: #567EDC;
      }
    }
  }
}
.previewFields, .styleFields {
  .stacksBlockContainer {
    height: 100%;
  }
}

#blockSettings > div {
    height: 100%;
}
.styleFields,
.previewFields {
  padding: 20px;
  overflow: auto;
  overflow-y: auto;
  overflow-wrap: break-word;
  width: 100%;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    height: calc(100% - 80px);
    max-height: 300px;
  }
  @media screen and (min-width: 768px) {
    float: left;
  }
  mat-label {
    display: block;
  }
}
.styleFields,
.previewFields {
  font-family: 'Poppins';
  .mat-list-item {
    height: auto !important;
  }
  ul {
    display: block;
    list-style: none;
    padding: 0;
    color: #676767;
    li {
      &.contentRight {
        justify-content: right;
      }
      &.contentCenter {
        justify-content: center;
      }
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .note {
        font-size: 11px;
        font-style: italic;
        margin-top: 30px;
      }
      mat-label {
        margin-right: 10px;
        width: 50%;
      }
    }
  }
  .listTitle {
    margin-top: 16px;
    font-size: 14px;
    color: #676767;
    font-weight: bold;
    font-family: 'Poppins';
  }
  .mat-accent .mat-slider-track-fill,
  .mat-accent .mat-slider-thumb,
  .mat-accent .mat-slider-thumb-label {
    background-color: #567edc;
  }
  .sectionSettings {
    .sectionHeader {
      height: 40px;
      display: flex;
      background: #44AF69;
      color: white;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      font-family: "Poppins";
    }
  }
  
}
#settingsTemplatesWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mat-form-field {
  max-width: 100%;
}

.tox-statusbar {
  @media screen and (max-width: 1024px) {
    display: none !important;
  }
}
.addItem {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 2px 12px 40px #00000029;
  border-radius: 5px;
  padding: 15px 30px;
  font: normal normal normal 15px/23px Poppins;
  color: #7694E1;
  cursor: pointer;
  display: block;
  text-align: center;
  border: none;
}