.previewMobile {
  .blockControlHandlers {
    .blockActionBtn {
      width: 30px;
      height: 30px;
    }
  }
}
.mobileAppPreview {
  width: 30%;
  height: 100%;
  display: block;
  float: left;
  .stickyHeader {
    position: sticky;
    top: 0;
    display: block;
    z-index: 8;
  }
  .previewMobile {
    box-sizing: border-box;
    position: relative;
    border: 1px dashed transparent;
    &.previewFooter {
      border: 0;
      &:hover {
        border-bottom: 0;
      }
    }
    &:hover {
      border: 1px dashed #567edc;
    }
    .blockControlHandlers {
      position: absolute;
      z-index: 3;
      right: 50%;
      left: calc(50% - 65px);
      height: 30px;
      top: -55px;
      border-bottom: 25px solid #567edc;
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
      width: 75px;
      visibility: hidden;
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &:hover {
     > .blockControlHandlers {
       visibility: visible;
       opacity: 1;
       transition: opacity 0.3s,
       visibility 0.3s;
       z-index: 100;
     }
    }
  }
  .stacksBlockContainer {
    height: auto;
    padding: 10px 20px 0;
    flex-grow: 1;
    max-width: 100%;
  }
  block-image,
  block-text,
  block-section,
  block-static,
  block-header,
  block-post,
  stacks-products,
  block-products,
  block-categories,
  block-slider,
  block-video,
  block-button {
    display: block;
  }
  #block-section.stacksBlockContainer {
    margin: 10px 10px 0;
    padding: 0;
    &.fullWidth {
      margin: 10px 0;
    }
  }
  #block-slider.stacksBlockContainer,
  #block-products.stacksBlockContainer,
  #block-categories.stacksBlockContainer,
  #block-posts.stacksBlockContainer
  {
    padding: 0 !important;
  }
  block-video {
    width: 100%;
    display: flex;

    .stacksBlockContainer {
      padding: 10px;
    }
  }
}
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  width: 300px !important;
  background: white;
}

.cdk-drag-placeholder {
  background: #f1f1f1;
  border: dotted 3px #d6d6d6;
  min-height: 10px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.columnBlockContainer.cdk-drop-list-dragging {
  background: #fffd5024;
}
.columnBlockContainer {
  border: 1px dashed #707070;
  min-height: 20px;
}

.mobileAppPreview.cdk-drop-list-dragging .previewMobile:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.previewImage {
  img {
    max-width: 100%;
    display: flex;
    width: 100%;
    box-sizing: border-box;
  }
}
.deleteBlock,
.blockHandle,
.editBlock {
  color: white;
  width: 20px;
  text-align: center;
  font-family: "Poppins";
  cursor: pointer;
  float: left;
  margin-top: 55px;
  border: 0;
  mat-icon {
    font-size: 16px;
    display: inline;
    line-height: 29px;
  }
}
.deleteBlock {
  left: auto;
  width: 20px !important;
  height: 20px !important;
  mat-icon {
    top: 0;
    line-height: 20px;
  }
}
.editBlock {
  left: auto;
  width: 20px !important;
  height: 20px !important;
  mat-icon {
    top: 0;
    line-height: 20px;
  }
}
.blockHandle {
  border-radius: 20px;
}
.columnBlockContainer {
  block-image:last-child, block-text:last-child {
    padding-bottom: 10px;
  }
}
.previewHeader {
   .blockControlHandlers {
    top: -20px !important;
    border-color: transparent !important;
    right: -40px !important;
    left: auto !important;
    .blockActionBtn {
      color: #567edc;
      background: white;
      border-radius: 20px;
      width: 30px !important;
      height: 30px !important;
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
      mat-icon {
        font-size: 20px;
        margin-top: 40px !important;
        line-height: 30px;
        background: transparent;
      }
    }
   }
}

.secondColumn,
.firstColumn {
  iframe {
    width: 86% !important;
  }
  .previewProducts {
    #style_1 {
      padding-left: 0;
      margin-right: 10px;
      .horizonal-slider {
          grid-template-columns: 1fr !important;
      }
    }
    #style_2 {
      .horizonal-slider {
        height: 220px !important;

        .item {
          width: 100%;
          max-width: 100% !important;
        }
      }
    }
    
  }
  .previewCategories {
    #style_3 {
      padding-left: 0;
      margin-right: 10px;
      .horizonal-slider {
        grid-template-columns: 1fr !important;
        display: block !important;
        .item {
          margin-bottom: 20px;
        }
      }
    }
    #style_4 {
      .horizonal-slider {
        height: 160px !important;
      }
    }
    .horizonal-slider {
      .item {
        width: 100%;
        max-width: 100% !important;
      }
    }
  }
}
.imageDiContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.imageDimensions {
  padding: 5px 10px;
  background: #567edc;
  color: white;
  font-family: 'Poppins';
  border-radius: 5px;
  margin: 5px 0px 5px 5px;
  display: inline-block;
}

#playType {
  .mat-radio-button {
    .mat-radio-outer-circle {
      border-color: #44af69 !important;
    }
    .mat-radio-inner-circle {
      background-color: #44af69 !important;
    }
  }
}

.cdkdrop-list {
  display: block;
}

.cdkdrag-box {
  display: block;
}

blocks-wrapper {
  .howItWorks {
    margin: 30px 0 0 30px; 
  } 
}

.previewText {
  padding-bottom: 10px;
  p {
      margin:0;
  }
}