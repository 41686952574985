.containerWrapper {
    width: 1232px;
    min-height: 740px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px -6px 32px #00000029;
    margin: 0 auto;
    box-sizing: border-box;
    @media screen and (max-width: 768px) {
        box-shadow: none;
        margin: 0;
        min-height: unset;
        display: flex;
        justify-content: center;
        align-content: center;
        width: 100%;
        flex-wrap: wrap;
    }
    h1 {
        padding: 40px;
        color: #6084DE;
        font-family: var(--main-font-family);
        letter-spacing: var(--main-letter-spacing);
        font-weight: bold;
        @media screen and (max-width: 768px) {
            text-align: center;
            padding: 15px;
            font-size: 18px;
            justify-content: center;
        }
    }
    #titleContainer, .titleContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .titleContainer {
        margin-left: 10px;
    }
    &.withSidebar {
        width: 1000px;
        float: left;
        margin-left: 40px;
        h1, .innerContainerContent {
            padding-left: 0 !important;

        }
    }
    &.noBG {
        box-shadow: none;
    }
}

.secondarySidebar {
    width: 220px;
    display: block;
    float: left;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    min-height: 1213px;
    margin: 00;
    box-shadow: 0px 1px 16px #00000029;
    .secondarySBTitle {
        h2 {
            color: #6084DE;
            padding: 20px 0;
            text-align: center;
            border-bottom: 1px solid #E3E3E3;
            margin-bottom: 0 !important;
        }
    }
    .secondarySBContent {
        ul {
            display: flex;
            flex-direction: column;
            margin: 0;
            li {
                letter-spacing: 0px;
                color: #5D5D5D;
                list-style: none;
                margin-top: 30px;
                font-size: 18px;
                cursor: pointer;
                display: block;
                width: 100%;
                &.selected {
                    color: #44AF69;
                }
            }
        }
    }
}

.howItWorks {
    color: #6f6f6f;
    font-family: var(--main-font-family);
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
        padding-right: 5px;
    }
}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.mat-dialog-container {
    padding-top: 15px !important;
}
app-view-builder {
    margin-left: -20px;
    @media screen and (min-width: 768px) {
        flex: 1 0 87%;
        display: flex;
        margin: 0 auto;
    }
    @media screen and (max-width: 768px) {
        max-width: 100%;
        width: 100%;
        #mobileWrapper {
            max-width: 100%;
        }
    }
    #mobileWidgetsWrapper {
        flex: 1 0 60%;
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
            position: sticky;
            width: 100%;
            // height: 88vh;
            overflow: hidden;
            box-sizing: border-box;
            justify-content: center;
        }
    }
}
login, signup, site-type, ltd {
    flex: 1 0 100%;
}
payment, payment-success {
    flex: 1 0 90%;
}
site-type {
    @media screen and (max-width: 768px) {
        display: flex;
        justify-content: center;
        max-width: 100%;
    }
}
.tourModeEnabled {
    template-view, app-connectivity {
        margin: 0 auto;
    }
}
expired {
    display: block;
    margin: 0 auto;
}
.tourModeEnabled {
    views {
        max-width: 1100px;
    }
}
views, help {
    width: 100%;
    box-shadow: 6px 0px 32px #00000029;
    margin: 0 auto;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
    .blockControlHandlers {
        display: none;
    }
}
new-product{
    @media screen and (max-width: 768px) {
      max-width: 100%;
    }
  }

  app-categories{
    @media screen and (max-width: 768px) {
        max-width: 100%;
      }
  }
.desktopOnly {
    @media screen and (max-width: 768px) {
        display: none !important;
    }
}
.mat-expansion-panel-content {
    .mat-expansion-panel-body {
      padding: 0;
    }
}
.mat-expansion-panel:not(.mat-expanded) {
    .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
        background: transparent;
    }
}
.mat-menu-panel {
    h4 {
        text-align: center;
        margin: 10px 0 10px 0;
    }
}
.formError {
    color: red;
}

analytics-page-stats {
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
}
@media screen and (max-width: 768px) {
    .desktopOnly {
        display: none;
    }
}