@import "/src/assets/scss/mixins/mixins-color.scss";

@mixin flex-center {
  align-items: center;
  display: flex;
}

.navbar {
  @include flex-center;
  // box-shadow: 0px -2px 5px 0px rgba(52, 56, 50, 0.05);
  border-radius: 0px;
  border-top: 1px solid rgba(114, 87, 255, 0.15);
  box-shadow: 0px -1px 10px 10px #3438321A;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
  .material-icons-outlined {
    font-size: 20px;
  }
}

.navbarHeight {
  @include flex-center;
  // box-shadow: 0px -2px 5px 0px rgba(52, 56, 50, 0.05);
  border-top: 1px solid rgba(114, 87, 255, 0.15);
  box-shadow: 0px -1px 10px 10px #3438321A;
  flex-wrap: wrap;
  height: auto;
  justify-content: space-evenly;
}

.nav-item {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: center;
  padding: 10px;
  opacity: 0.5;
  text-align: center; 
  transition: border 0.3s ease, color 0.3s ease;
}

.nav-item:first-child {
  border-top: 3px solid var(--ion-color-primary);
  opacity: 1;
  padding-top: 7px;
}

.nav-item p {
  @include font-style('regularS');
  margin: 5px 0;
  font-size: 10px;
}

/* Additional styles for the expand-navbar */

.expandHeight {
  @include flex-center;
  border-radius: 10px;
  box-shadow: 0px -2px 5px 0px rgba(52, 56, 50, 0.05);
  flex-wrap: wrap;
  height: auto;
  justify-content: space-evenly;
  width: 100%;
}

.expand-navbar {
    @include flex-center;
    border-radius: 10px;
    border: 1px solid rgba(114, 87, 255, 0.15);
    box-shadow: 0px -2px 5px 0px rgba(52, 56, 50, 0.05);
    justify-content: space-evenly;
    width: 100%;
}

.expand-nav-item {
  border-collapse: collapse;
  border-spacing: 0;
  opacity: 0.5;
  text-align: center;
  transition: border 0.3s ease, color 0.3s ease;
  margin: 13px 7px;
}

.expand-nav-item p {
  @include font-style('regularS');
  display: none;
  opacity: 0;
  position: relative;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  vertical-align: middle;
  visibility: hidden;
}

.expand-nav-item:first-child {
  background: var(--ion-color-primary-opacity);
  border-radius: 10px;
  opacity: 1;
  height: 70%;
}

.expand-nav-item:first-child p {
  @include font-style('regularS');
  display: table-cell;
  opacity: 1;
  padding-right: 10px;
  visibility: visible;
}

.expand-nav-item:first-child i,
.expand-nav-item i  {
  display: table-cell;
  height: 40px;
  padding: 0 10px;
  position: relative;
  vertical-align: middle;
}

// .expand-nav-item:first-child:active i,
// .expand-nav-item:active i {
//   border: 1px solid map-get($colorStyles, 'primary-15');
// }

// .expand-nav-item:not(:active) {
//   color: map-get($colorStyles, 'primary-50');
// }



/* Additional styles for the CircleNavbar */

.CircleNavbarHeight {
  @include flex-center;
  background-color: transparent;
  border-radius: 7px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0;
  height: auto;
  width: 100%;
}

.CircleNavbar {
  @include flex-center;
  background-color: transparent;
  border-radius: 7px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0;
  height: 80px;
  width: 100%;
}
.CircleNavItem {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 10px;
  position: relative;
  opacity: 1;
}

.CircleNavItem p {
  @include font-style('regularS');
  display: block;
  margin: 5px 0;
  text-align: center; 
  transition: opacity 0.3s ease;
}

.CircleNavItem i {
  display: block;
  margin-bottom: 5px;
  margin: 0 auto;
  position: relative;
  text-align: center;
}

// .CircleNavItem:first-child,
// .special-icon .shopping-bag-wrapper {
//   opacity: 1 !important;
// }

.special-icon .shopping-bag-wrapper {
  background-color: var(--ion-color-primary);
  border-radius: 40px;
  box-shadow: 0px 5px 0px 5px #3438321A; 
  padding: 15px;
  position: relative;
  top: -40px;
  z-index: 1;
}

.special-icon i {
  color: #FFFFFF !important;
}