table {
    &.fullWidthTable  {
        width: 100%;
    }
    td.mat-cell {
        padding: 10px;
    }
}
self, cloud {
    table {
        td {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.dateModule {
    width: 500px;
    mat-form-field {
        margin-right: 40px;
    }
    .dateActionBtn {
        margin-right: 20px;
        margin-bottom: 20px;
    }
}

.innerContainerContent {
    mat-toolbar {
        background: #44AF69;
        color: white;
    }
    .dateActionBtn {
        float: left;
        margin-left: 0;
    }
}

@media screen and (max-width: 768px) {
    .dateModule {
        width: 40%;
        display: flex;
        flex-direction: column;
        padding: 20px;
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 auto;
    }
}