@import "/src/assets/scss/mixins/mixins-color.scss";

@mixin search-bar {
  align-items: center;
  display: flex;
  position: relative; 
}

@mixin search-wrapper {
  align-items: center;
  display: flex;
  position: relative;
}

@mixin search-input {
  border-radius: 5px;
  border: 1px solid #ccc;
  color: map-get($colorStyles, 'primary');
  padding: 10px 30px 10px 10px; 
  position: relative; 
  &:focus {
    border-color: map-get($colorStyles, 'primary');
  }
}

@mixin search-button {
  background-color: transparent;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  padding: 10px;
  position: absolute; 
  right: 10px; 
  z-index: 1;
}

.search-bar {
  @include search-bar;
  @include font-style('Semibold-L');
}

.search-wrapper {
  @include search-wrapper;
}

.search-input {
  @include search-input;
}

.search-button {
  @include search-button;
}
