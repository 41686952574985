
@import "/src/assets/scss/mixins/mixins-color.scss";


@mixin dropdown() {
  display: inline-block;
  position: relative;
}

@mixin dropdown-input() {
  border-radius: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 200px;
}

@mixin dropdown-content() {
  background-color: #fff;
  border-top: none;
  border: 1px solid #ccc;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}

@mixin dropdown-item() {
  color: map-get($colorStyles, 'primary');
  display: block;
  padding: 10px;
  text-decoration: none;
}

@mixin dropdown-item-hover() {
  &:hover {

    background: map-get($colorStyles, 'primary-15');
  }
}

.dropdown {
  @include dropdown();  
}

.dropdown-input {
  @include dropdown-input();
}

.dropdown-content {
  @include dropdown-content();
}

.dropdown-item {
  @include dropdown-item();
}

.dropdown-item:hover {
  @include dropdown-item-hover();
}
