.settingsWrapper {
  margin-top: 40px;
  width: 100%;
  min-height: 1000px;
  max-width: 100%;
  background: white;
  box-shadow: -7px 18px 53px #00000029;
  border: 1px solid #F2F2F2;
  border-radius: 10px;
  @media screen and (max-width: 768px) {
    box-shadow: none;
    border: 0;
    border-radius: 0;
    margin: 0;
  }
  h1 {
    color: #6084DE;
    font-family: 'Poppins';
    font-size: 28px;
    margin: 40px 45px;
    font-weight: 500;
    text-align: center;
  }
  .settingsSubHeading {
    font-size: 28px;
    margin: 30px 45px;
    font-weight: bold;
  }
  .sectionHint {
    margin: 30px 45px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 80px;
    // &.colorsSettingsWrapper {
    //   li {
    //     width: 25%;
    //   }
    // }
    @media screen and (max-width: 768px) {
      margin: 0;
      padding: 0;
    }
    &.alignRight {
      justify-content: right;
    }
    li {
      @media screen and (max-width: 1170px) {
        width: 80%;
      }
      display: flex;
      flex-direction: column;
      list-style: none;
      margin-bottom: 20px;
      max-width: 600px;
      width: 50%;
      mat-form-field {
        width: 80%;
        height: 30px;
        padding: 10px;
        font-family: 'Poppins';
        color: #a0a0b1;
        margin-bottom: 20px;
        &:focus {
          box-shadow: 2px 12px 40px #4d48cd29 !important;
          outline: 0;
        }
        &.halfField {
          width: 40%;
        }
      }
      
      .fileUpload {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 5px;
        box-shadow: 2px 12px 40px #00000029;
        color: #7694E1;
        cursor: pointer;
        font: normal normal normal 15px/23px Poppins;
        padding: 15px 30px;
        width: 70%;
        input {
          display: none;
        }
        @media screen and (max-width: 768px) {
          float: left;
        }
      }

      .fileUploadTitle {
        font: 400 16px/28px Roboto,
          "Helvetica Neue",
          sans-serif;
        letter-spacing: normal;
        margin: 0 0 30px;
        float: left;
        margin-right: 30px;
        @media screen and (max-width: 1170px) {
          width: 100%;
          display: block;
        }
      }
    }
    ul {
      flex-direction: column;
      padding: 0;
      li {
        margin-bottom: 10px;
        list-style: circle;
      }
    }
    a {
      color: #7884de;
    }
  }
  h2 {
    font: normal normal 600 22px/33px Poppins;
    color: #242424;
  }
  p, ul li {
    font: italic normal 300 15px/26px Poppins;
    color: #A0A0A0;
  }
  input, textarea {
    font:  16px/25px Poppins;
    color: #474747;
  }
}
content-settings {
  min-width: 50%;
}
general-settings,
content-settings,
application-settings,
apple-settings {
  display: flex;
  justify-content: center;
  padding: 0 20px;
  max-width: 80%;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    padding: 0;
    max-width: 100%;
  }
}
application-settings {
  width: 75%;
}
ngx-mat-color-toggle {
  mat-icon {
    font-size: 20px !important;
  }
}
.colorLabel {
  width: 30px;
  height: 30px;
  border: 0;
  cursor: pointer;
  border-radius: 50%;
  float: right;
}

.colorPicker {
  float: right;
  height: 30px;
  margin-top: 0;
  width: 30px;
}

.colorPickerValue {
  display: inline;
  color: black;
  font-style: normal;
}