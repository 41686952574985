/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/variables.scss";
@import "./assets/scss/settings.scss";
@import "./assets/scss/blocks-sidebar.scss";
@import "./assets/scss/mobile-preview.scss";
@import "./assets/scss/app-settings.scss";
@import "./assets/scss/components/buttons.scss";
@import "./assets/scss/components/tables.scss";
@import "./assets/scss/main.scss";
@import "./assets/scss/arabic.scss";
@import "./assets/scss/translate.scss";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cacaca;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
html {
  --mdc-icon-button-icon-size: 18px;
  --mat-form-field-container-height: 46px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 11px;
  --mat-form-field-filled-with-label-container-padding-top: 10px;
  --mat-form-field-filled-with-label-container-padding-bottom: 0px;
  --mdc-outlined-text-field-label-text-size: 14px;
  --mat-icon-button-hover-state-layer-opacity: 0;
  --mat-icon-button-pressed-state-layer-opacity: 0;
}

.mat-mdc-tab-body-wrapper {
  margin-top: -10px;
  z-index: 0;
}
.mat-mdc-tab-list {
  height: 90px;
}

