$colorStyles: (
  'dark-10': #3438321A,
  'dark-15': #34383226,
  'dark-25': #34383240,
  'dark-50': #34383280, 
  'dark': #343832,
  'primary-15': #7257FF26,
  'primary-25': #7257FF40,
  'primary-50': #7257FF80,
  'primary': #7257FF,
);


@mixin color-style($style) {
  $styleDefinition: map-get($colorStyles, $style);
  
  @if $styleDefinition {
    @if type-of($styleDefinition) == map {
      @each $property, $value in $styleDefinition {
        #{$property}: $value;
      }
    } @else {
      background-color: $styleDefinition;
    }
  }
}

.color-box {
  height: 50px; /* Adjust the height as needed */
  margin: 5px; /* Add margin for spacing */
}

// Apply colors using the mixin
.color-box.primary {
  @include color-style('primary');
}

.color-box.primary-50 {
  @include color-style('primary-50');
}

.color-box.primary-25 {
  @include color-style('primary-25');
}

.color-box.primary-15 {
  @include color-style('primary-15');
}

.color-box.dark {
  @include color-style('dark');
}

.color-box.dark-50 {
  @include color-style('dark-50');
}

.color-box.dark-25 {
  @include color-style('dark-25');
}

.color-box.dark-15 {
  @include color-style('dark-15');
}

.color-box.dark-10 {
  @include color-style('dark-10');
}
