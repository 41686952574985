@import "/src/assets/scss/mixins/mixins-color.scss";

$input-styles: (
  'defaultN': (
    border: 1px solid #ccc,
    color: #333,
    font-family: 'Poppins'
  ),
  'activeN': (
    border: 2px solid #00f,
    color: #00f,
    font-family: 'Poppins'
  ),
  'inactiveN': (
    border: 1px dashed #aaa,
    color: #aaa,
    font-family: 'Poppins'
  ),
);

@mixin input-style($state) {
  $styles: map-get($input-styles, $state);

  @if $styles {
    @each $property, $value in $styles {
      #{$property}: $value;
    }
  }
}

.phone-contN {
  align-items: center;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  margin: 10px;
  padding: 14px 15px;
  .phone-input-number-flag {
    margin-right: 5px;
  }

  .phone-input-number-input {
    @include input-style('defaultN');
    align-items: center;
    border-radius: 10px; 
    gap: 10px;
    margin: 10px;
    padding: 14px 15px;

    &:focus {
      @include input-style('activeN');
    }

    &:disabled {
      @include input-style('inactiveN');
    }
  }
}


