@import "/src/assets/scss/mixins/mixins-color.scss";

@mixin custom-text-area {
    textarea {
      border-radius: 8px; 
      border: 1.5px solid #09042026;
      box-sizing: border-box;
      height: 220px;
      margin: 25px;
      outline: none;
      padding: 10px;
      resize: none; 
      width: 350;
    }
  }
  
  .custom-text-area {
    @include custom-text-area;
    @include font-style('Semibold-L');
  }
  