#block-footer {
    height: 60px;
      .previewFooter {
        bottom: 0;
        display: block;
        left: 0;
        position: absolute;
        width: 100%;
        img {
          width: 100%;
        }
        &.expandPreviewFooter {
          border: 0;
        }
      }
      
      .menuLinker { 
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        li {
          margin-bottom: 0;
        }
      }
  }
  .MenuItemContainer {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .MenuColorContainer {
    display: flex;
    flex-direction: row;
     label {
      position: absolute;
      right: 10px;
      top: 68px;
      z-index: 1;
     }
  }
  .contentContainerColor {
    flex-direction: column;
    
    label {
      padding-bottom: 15px;
    }
    mat-form-field {
      height: 70px;
    }
  }
  .footerWrapper {
      align-items: center;
      // border-top: 1px solid rgba(114, 87, 255, 0.15);
      display: flex;
      // box-shadow: 0px -1px 10px 10px rgba(52, 56, 50, 0.05);
      height: auto;
      justify-content: space-around;
      position: relative;
      margin: 0 10px;
      transition: height 0.2s ease-out;
      z-index: 3;
      &.expandFooterWrapper {
        margin: 5px;
      }
      &.inactive {
          background-color: grey !important; // dont remove this 
          height: 5px;
          overflow: hidden;
      }
      ul {
          align-items: center;
          display: flex;
          height: 100%;
          justify-content: space-around;
          list-style: none;
          margin: 0;
          padding: 0;
          text-align: center;
          width: 100%;
          li {
          align-items: center;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          padding: 15px;

        

          p {
              color: var(--ion-color-primary) !important; 
              font-size: 12px;
              margin: 0;
          }
          span {
            color: var(--ion-color-primary) !important; 
            padding: 5px;
          } 
          }
      }

      li:active {
        background: var(--primary-primary-15, rgba(114, 87, 255, 0.15));
      }
  }
  .globalColors {
    i, p, .nav-item:first-child {
      color: var(--ion-color-primary) !important;
    }
  }

  .mat-form-field{
    margin-top: 20px;
  }

  .deleteMenuItem{
    border: none;
    margin-right: 10px;
    color: #CB2121;
  }